import classNames from 'classnames'
import styles from './Hero.module.scss'

const Hero = ({ children, className }) => {
    const classes = classNames(styles.heroImage, className)

    return (
        <div className={classes}>
            { children }
        </div>
    )
}

export default Hero
