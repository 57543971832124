import { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { node, string, bool, object, shape } from 'prop-types'

import { MastheadSiteNav } from '@saatva-bits/pattern-library.modules.navigation'
import { PromotionBanner } from '@saatva-bits/pattern-library.modules.promotions'
import Footer from '@/layouts/Footer'

import useDeferScripts from '@/hooks/useDeferScripts'

import { getDataLayerData } from '@/utils/datalayer'
import { open as openChat, useChatAutoPopup } from '@saatva-bits/pattern-library.utils.chat'
import { navigationDataType } from '@/types'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

export default function ErrorLayout({
    children,
    applyDoNotIndex,
    description,
    title,
    pageType = '',
    customCanonical,
    navigationData,
    footerData
}) {
    useDeferScripts()
    useChatAutoPopup(90000)
    const { asPath } = useRouter()

    useEffect(() => {
        // Instana pages config
        if (typeof window !== 'undefined') {
            window.ineum?.('page', window.location.pathname)
        }
    }, [])

    const dataLayerData = getDataLayerData(pageType, {}, {})

    const path = customCanonical || asPath.split('#')[0].split('?')[0].replace(/\/$/, '')
    const canonicalUrlPath = `https://www.${process.env.NEXT_PUBLIC_STOREFRONT_CORESITE_NODE_DOMAIN}${path}`

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="description" content={description || 'The highest quality handcrafted mattresses, bedding & furniture at the most comfortable prices.'} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {applyDoNotIndex && <meta name="robots" content="noindex" />}
                <link rel="icon" href="/favicon.ico" />
                <link rel="canonical" href={canonicalUrlPath} />
                <script dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(${JSON.stringify(dataLayerData)});
                    `
                }} />
            </Head>
            <main>
                <PromotionBanner promotionData={{}}/>
                <header className={'headerClasses'} id="sticky-header">
                    <MastheadSiteNav
                        handleChat={openChat}
                        topLevelItems={navigationData}
                        imgixDomain={IMGIX_URL}
                        hideSearchIcon={true}
                        hideVrIcon={true}
                        hideCart={true}
                        hideOrderTracker={false}
                        hideCartFlyout={true}
                    />
                </header>
                {children}
                {/* TODO: add fallback data once available from module */}
                {footerData && <Footer footerData={footerData} /> }
            </main>
        </>
    )
}

ErrorLayout.propTypes = {
    children: node.isRequired,
    applyDoNotIndex: bool,
    description: string,
    title: string.isRequired,
    navigationData: navigationDataType,
    launchDarklyContextData: shape({
        experiments: object,
        featureFlags: object,
        flagOverrides: object
    }),
    pageType: string,
}
