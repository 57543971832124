import classNames from 'classnames'

import { open as OpenChat } from '@saatva-bits/pattern-library.utils.chat'

import ErrorLayout from '@/layouts/Error'
import Hero from '@/components/Hero'

import styles from './Error.module.scss'

/**
 * @param {object} props
 * @param {'notFound' | 'error'} props.errorType
 * @param {object?} props.errorData
 * @param {string} props.errorData.message
 * @param {string} props.errorData.stack
 */
const ErrorView = (props) => {
    let { errorData, errorType, ...rest } = props

    const classes = classNames('section', styles.hero)
    const heading1Classes = classNames('t-heading1', styles.heading1)
    const heading2Classes = classNames('t-heading2 u-marginBottom--none', styles.heading2)
    const heroContactClasses = classNames('u-hidden--md-down', { [styles.noMargin]: errorType === 'error' })

    return (
        <ErrorLayout {...rest}>
            <Hero className={classes}>
                <div className='container'>
                    <h1 className={heading1Classes}>{errorType === 'notFound' ? 'Oops. You caught us napping.' : 'Oops! Something went wrong!'}</h1>
                    {errorType === 'error' && <h2 className={heading2Classes}>Our engineers have been notified.</h2>}
                    <ContactUs errorType={errorType} classNames={heroContactClasses} />
                </div>
            </Hero>
            <ContactUs errorType={errorType} classNames='u-hidden--md-up u-textCenter' />
            {errorData && <aside>
                <h2>{errorData.message}</h2>
                <pre>{errorData.stack}</pre>
            </aside>}
        </ErrorLayout>
    )
}

/**
 *
 * @param {object} props
 * @param {'notFound' | 'error'} props.errorType
 * @param {string} props.classNames
 */
const ContactUs = (props) => {
    const classes = classNames(styles.contactUs, props.classNames)
    if (props.errorType === 'notFound') {
        return <div className={classes}>The page you are looking for isn't here, but our sleep guides are always awake to help with any questions you have. <a className="t-link" href="tel:1-877-672-2882">Call</a> or <a className="t-link" onClick={OpenChat}>chat</a> with us anytime day or night.</div>
    } else {
        return <div className={classes}>Our sleep guides are always awake to help with any questions you have. <a className="t-link" href="tel:1-877-672-2882">Call</a> or <button type="button" className="t-link" onClick={OpenChat}>chat</button> with us anytime day or night.</div>
    }
}

export default ErrorView
