import { Component } from 'react'
import ErrorView from '@/views/Error'
import { handleError } from '@/utils/errorHandling'

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
    
        // Define a state variable to track whether is an error or not
        this.state = { hasError: false }
    }
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI
        return { hasError: true }
    }
    componentDidCatch(error) {
        // You can use your own error logging service here
        handleError(error)
    }
    render() {
        // Check if the error is thrown
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorView {...this.props} />
        }
    
        // Return children components in case of no error
    
        return this.props.children
    }
}

export default ErrorBoundary
