import { buildDataLayerExperiments } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.utils'

export const getDataLayerData = (pageType, experiments, flagOverrides) => {
    const dataLayerExperiments = buildDataLayerExperiments(experiments, flagOverrides)

    return {
        event: 'pageview',
        pageType, 
        ...dataLayerExperiments
    }
}

/**
 * Validates that window object exists
 *
 * eventObject expects at the bare minimum event property with a name
 * can pass any other property along deemed necessary by Digital tools for the events metadata
 * @param {{ event: string }} eventObject
 */
export const pushDataLayerEvent = (eventObject) => {
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({...eventObject})
    }
}

/* Reference other applications leveraging data layer events and determine if they should be shared or duplicated */
