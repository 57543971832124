import { useEffect } from 'react'

import { init as ChatInit } from '@saatva-bits/pattern-library.utils.chat'

const timeoutDuration = 3000

function useDeferScripts () {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const timer = setTimeout(() => {
                ChatInit()
            }, timeoutDuration)

            return () => {
                clearTimeout(timer)
            }
        }
    })
}

export default useDeferScripts
